import { MediaLivestream } from '@/components/Media/MediaLivestream';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { LivestreamStatus } from '@/types/global';
import { logger } from '@/utils/logging/logger';
import { BoardData } from 'src/pages/[[...board]]';

import { IMAGE_SIZE_ONE_THIRD } from '../../BoardImageSizes';

type Props = {
	rundschau: NonNullable<BoardData['board']>['rundschau'];
};

const BoardSectionItemBR24Rundschau = ({ rundschau }: Props) => {
	const { images } = useEnvironment();
	if (!rundschau) {
		logger.error(`'episode' not found.`, 'BoardSectionItemBR24Rundschau');

		return null;
	}

	const newEpisode = rundschau?.episodes?.nodes?.[0];
	const oldEpisode = rundschau?.episodes?.nodes?.[1];

	const newEpisodeStartDate = new Date(newEpisode?.initialScreening?.start);
	const calcStartDate = new Date(newEpisodeStartDate.setMinutes(newEpisodeStartDate.getMinutes() - 15));
	const currentDate = new Date(Date.now());

	const currentVideo = calcStartDate < currentDate ? newEpisode : oldEpisode;

	let startDate;
	let status: LivestreamStatus;
	let thumbnailUrl;
	let thumbnailCopyright;
	let thumbnailAltText;

	if (calcStartDate <= currentDate && new Date(currentVideo?.initialScreening?.start) >= currentDate) {
		status = 'FUTURE';
		startDate = currentVideo?.initialScreening?.start;
		thumbnailUrl = currentVideo?.defaultTeaserImage?.url;
		thumbnailCopyright = currentVideo?.defaultTeaserImage?.copyright;
		thumbnailAltText = currentVideo?.defaultTeaserImage?.title;
	} else if (new Date(currentVideo?.initialScreening?.end) >= currentDate) {
		status = 'NOW_LIVE';
		startDate = currentVideo?.initialScreening?.start;
		thumbnailUrl = currentVideo?.defaultTeaserImage?.url;
		thumbnailCopyright = currentVideo?.defaultTeaserImage?.copyright;
		thumbnailAltText = currentVideo?.defaultTeaserImage?.title;
	} else if (new Date(currentVideo?.initialScreening?.end) <= currentDate && currentVideo?.videoFiles?.nodes?.length === 0) {
		startDate = oldEpisode?.initialScreening?.start;
		status = 'PAST';
		thumbnailUrl = oldEpisode?.defaultTeaserImage?.url;
		thumbnailCopyright = oldEpisode?.defaultTeaserImage?.copyright;
		thumbnailAltText = oldEpisode?.defaultTeaserImage?.title;
	} else {
		status = 'PAST';
		startDate = currentVideo?.initialScreening?.start;
		thumbnailUrl = currentVideo?.defaultTeaserImage?.url;
		thumbnailCopyright = currentVideo?.defaultTeaserImage?.copyright;
		thumbnailAltText = currentVideo?.defaultTeaserImage?.title;
	}

	// if old videoFiles of new episode have not finished encoding, use old episode videoFiles. Make sure there is always a videoFile.
	const videoFiles = (currentVideo?.videoFiles?.nodes?.length ?? 0) > 0 ? currentVideo?.videoFiles?.nodes : oldEpisode?.videoFiles?.nodes;
	const liveStreamUrl = currentVideo?.broadcasts?.nodes?.[0]?.broadcastedOn?.nodes?.[0]?.streamingUrls?.nodes?.[0]?.publicLocation || null;
	const publicationDate = currentVideo?.modifiedAt || null;

	return (
		<MediaLivestream
			copyright=""
			customTitle="BR24 im TV"
			isRundschau={true}
			livestreamEndDate={currentVideo?.initialScreening?.end || null}
			// always use the current live channel as the livestream url
			liveStreamHlsUrl={liveStreamUrl || null}
			livestreamStartDate={startDate || null}
			livestreamStatus={status}
			moduleId={'rundschau'} // Nach Absprache mit David H.
			thumbnailAltText={thumbnailAltText || null}
			thumbnailCopyright={thumbnailCopyright || null}
			thumbnailLink={null}
			thumbnailUrl={thumbnailUrl || images.DEFAULT_RUNDSCHAU_IMAGE}
			videos={videoFiles}
			title="BR24 im TV"
			publicationDate={publicationDate}
			sizes={IMAGE_SIZE_ONE_THIRD}
		/>
	);
};

export default BoardSectionItemBR24Rundschau;
