import { HTMLAttributes, ReactNode } from 'react';

import styles from './LinkList.module.scss';

type Props = {
	children: ReactNode;
	isVertical?: boolean;
} & HTMLAttributes<HTMLUListElement>;

const LinkList = ({ children, isVertical, ...rest }: Props) => {
	return (
		<ul className={`${styles.list} ${isVertical ? styles.isVertical : ''}`} {...rest}>
			{children}
		</ul>
	);
};

export default LinkList;
